import { gql } from "@apollo/client";

export const GET_ORGANIZATIONS_WITH_SLUG_QUERY = gql`
  query getOrganizationsWithSlugs {
    organizations {
      name
      slug
    }
  }
`;

type Organization = {
  name: string;
  slug: string;
};

export type OrganizationsQueryResult = {
  organizations?: [Organization];
};
