import { gql } from "@apollo/client";

export const GET_INVOICES = gql`
  query GetUserInvoices($includeRefunded: Boolean) {
    invoices(includeRefunded: $includeRefunded) {
      amountPaidCents
      createdAt
      id
      number
      stripePaymentUrl
    }
  }
`;
