import { Center, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import ResultsPage from "./ResultsPage";
import { Review } from "./Review/Review";
import VehicleDetails from "./VehicleDetails";
import OfficeSelectorModal from "../../components/OfficeSelectorModal";
import useApp from "../../contexts/AppContext";

import type { Vehicle } from "../../types";

export interface EnchancedRoute {
  selectedVehicle?: Vehicle | undefined;
  setSelectedVehicle?: (vehicle: Vehicle | undefined) => void;
  deliveryOption?: string;
  setDeliveryOption?: (option: string) => void;
  vehicles?: Vehicle[];
  nextRoute?: EnchancedRoute | undefined;
  prevRoute?: EnchancedRoute | undefined;
  key?: string;
  hasDirectShipFlow?: boolean;
  slug?: string;
}

export const WIZARD_STEPS = [
  {
    label: "Overview",
    path: "",
    component: <ResultsPage />,
    exact: true,
  },
  {
    label: "Vehicle",
    path: "/options",
    component: <VehicleDetails />,
    exact: true,
  },
  { label: "Review", path: "/review", component: <Review />, exact: false },
];

function Wizard(): JSX.Element {
  const { slug } = useParams<{ slug: string }>();
  const {
    isLoading,
    availableVehicles: vehicles,
    subscription,
    order,
    selectedVehicle: storedVehicle,
    setSelectedVehicle: setStoredVehicle,
    setSelectedVariant: setStoredVariant,
    selectedOffice,
    hasDirectShipFlow,
    waitlistSubscription,
  } = useApp();

  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>(
    storedVehicle,
  );
  const [deliveryOption, setDeliveryOption] = useState("pickup");
  const { path } = useRouteMatch();
  const isFirstRoute = useRouteMatch({
    path: "/:slug/wizard",
    strict: true,
  })?.isExact;
  const isVehiclesRoute = useRouteMatch({
    path: "/:slug/wizard",
    strict: true,
  })?.isExact;
  const location = useLocation();
  const history = useHistory();

  const enhancedChildrens = WIZARD_STEPS.map((route, index: number) => {
    const isFirst = index === 0;
    const isLast = WIZARD_STEPS.length - 1 === index;
    const el = React.cloneElement(route.component, {
      selectedVehicle,
      setSelectedVehicle,
      deliveryOption,
      setDeliveryOption,
      vehicles,
      nextRoute: isLast ? undefined : WIZARD_STEPS[index + 1],
      prevRoute: isFirst ? undefined : WIZARD_STEPS[index - 1],
      key: route.label,
      hasDirectShipFlow,
      slug,
    });

    return { path: route.path, component: el, key: index, exact: route.exact };
  });

  const leaseToOwnTab = selectedOffice?.leaseToOwnTabVisible;
  const subscriptionTab = selectedOffice?.subscriptionTabVisible;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (waitlistSubscription?.id && !isFirstRoute) {
      history.replace(`/${slug}/wizard`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, waitlistSubscription, isFirstRoute, history]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isVehiclesRoute && selectedVehicle) {
      setStoredVehicle("");
      setStoredVariant("");
      setSelectedVehicle(undefined);
    }
    // eslint-disable-next-line
	}, [isVehiclesRoute, selectedVehicle, location]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!storedVehicle || !vehicles.length || !selectedVehicle) {
      return;
    }

    const found = vehicles.find((vehicle) => {
      return (
        vehicle.id === storedVehicle?.id &&
        vehicle.brand === storedVehicle?.brand &&
        vehicle.model === storedVehicle?.model
      );
    });

    if (!found) {
      return;
    }

    // If some property is different, backend will prevail
    if (JSON.stringify(found) !== JSON.stringify(selectedVehicle)) {
      setStoredVehicle(found);
      setSelectedVehicle(found);
    }
    // eslint-disable-next-line
	}, [vehicles]);

  if (subscription || order) {
    return <Redirect to={`/${slug}`} />;
  }

  return (
    <>
      <OfficeSelectorModal isOpen={!selectedOffice} />
      {isLoading && !selectedOffice && (
        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="pandaRed.500"
            size="xl"
          />
        </Center>
      )}
      {(subscriptionTab || leaseToOwnTab) && (
        <Switch>
          {enhancedChildrens.map((el) => {
            return (
              <Route path={`${path}${el.path}`} key={el.key} exact={el.exact}>
                {el.component}
              </Route>
            );
          })}

          <Route>
            <Redirect to={`/${slug}/wizard`} />
          </Route>
        </Switch>
      )}
    </>
  );
}

export default Wizard;
