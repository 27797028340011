import { gql } from "@apollo/client";
import secureLocalStorage from "react-secure-storage";

import { apolloMutate } from "../services/apolloClient";
import { Price } from "../types";

export const CHECKOUT_SESSION_CACHE_KEY = "ridepanda.checkoutSession";

export type InvoiceLines = {
  taxRate?: Price; // Deprecated
  taxAmount?: Price;
  discount?: Price;
  total: Price;
};

type StripeSecret = {
  clientSecret: string;
  clientSecretType: string;
};

export type CheckoutSession = InvoiceLines & StripeSecret;

export interface CheckoutSessionData {
  checkoutSessionCreate: CheckoutSession;
}

interface StoredCheckoutSession {
  data: CheckoutSession;
  organizationSlug: string;
  hubId: string;
  variantId: string;
  email: string;
  createdAt: Date;
}

const checkoutMutation = gql`
  mutation CheckoutSessionCreate(
    $variantId: ID!
    $officeId: ID!
    $email: String!
    $organizationSlug: String!
  ) {
    checkoutSessionCreate(
      variantId: $variantId
      officeId: $officeId
      email: $email
      organizationSlug: $organizationSlug
    ) {
      clientSecret
      clientSecretType
      taxRate {
        amount
        displayName
      }
      taxAmount {
        amount
        displayName
      }
      discount {
        amount
        displayName
      }
      total {
        amount
      }
    }
  }
`;

function getCachedCheckoutSession({
  organizationSlug,
  variantId,
  hubId,
  email,
}: Omit<StoredCheckoutSession, "data" | "createdAt">) {
  const cachedSession = secureLocalStorage.getItem(
    CHECKOUT_SESSION_CACHE_KEY,
  ) as string;

  if (!cachedSession) {
    return null;
  }

  const parsedSession: StoredCheckoutSession = JSON.parse(cachedSession);

  if (
    parsedSession.organizationSlug !== organizationSlug ||
    parsedSession.variantId !== variantId ||
    parsedSession.hubId !== hubId ||
    parsedSession.email !== email
  ) {
    return null;
  }

  const sessionCreatedAt = new Date(parsedSession.createdAt);
  const msTimeDiff = Math.abs((new Date() as any) - (sessionCreatedAt as any));

  // cached session is older than 1 hour
  if (msTimeDiff / 1000 / 60 / 60 > 1) {
    return null;
  }

  return parsedSession.data;
}

function storeCheckoutSession({
  data,
  organizationSlug,
  variantId,
  hubId,
  email,
}: Omit<StoredCheckoutSession, "createdAt">) {
  const newData: StoredCheckoutSession = {
    data,
    organizationSlug,
    variantId,
    hubId,
    email,
    createdAt: new Date(),
  };

  secureLocalStorage.setItem(
    CHECKOUT_SESSION_CACHE_KEY,
    JSON.stringify(newData),
  );
}

type CreateCheckoutSessionParams = {
  organizationSlug: string;
  variantId: string;
  officeId: string;
  email: string;
};

export const createCheckoutSession = async (
  variables: CreateCheckoutSessionParams,
  hubId: string,
  forceFetch = false,
) => {
  const cachedSession = getCachedCheckoutSession({ ...variables, hubId });

  if (cachedSession && !forceFetch) {
    return new Promise((resolve) => {
      resolve(cachedSession);
    });
  }

  const { data } = await apolloMutate({
    mutation: checkoutMutation,
    variables,
  });

  storeCheckoutSession({
    data,
    hubId,
    ...variables,
  });

  return new Promise((resolve) => {
    resolve(data as CheckoutSession);
  });
};
