/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable class-methods-use-this */

import ahoy from "./ahoy";
import { getSlug } from "./apolloClient";
import { auth } from "./firebase";
import { Office } from "../api/offices";
import { getItem } from "../hooks/useLocalStorage";
import { Variant, Vehicle } from "../types";

type EventProperties = Record<string, unknown>;

declare global {
  interface Window {
    heap?: {
      track: (name: string, properties?: EventProperties) => boolean;
    };
    tracker?: Tracker;
  }
}

class Tracker {
  private static instance: Tracker;

  private firebaseId: string | undefined;

  private email: string | null | undefined;

  private constructor() {}

  public static getInstance(): Tracker {
    if (!Tracker.instance) {
      Tracker.instance = new Tracker();
    }

    return Tracker.instance;
  }

  public setFirebaseId(id: string | undefined) {
    this.firebaseId = id;
  }

  public setEmail(email: string | undefined | null) {
    this.email = email;
  }

  public async trackEvent(
    eventName: string,
    eventProperties?: EventProperties,
  ) {
    await this.setAuthHeader();

    ahoy.track(eventName, { ...this.data(), ...eventProperties });
    window.heap?.track(eventName, { ...this.data(), ...eventProperties });
  }

  public async trackView() {
    await this.setAuthHeader();

    this.trackEvent("$view", {
      ...this.data(),
      url: window.location.href,
      title: document.title,
      page: window.location.pathname,
    });
  }

  private async setAuthHeader() {
    const accessToken = await auth.currentUser?.getIdToken();
    const authorization = accessToken ? `Bearer ${accessToken}` : "";

    ahoy.configure({
      withCredentials: true,
      headers: {
        authorization,
        "Ahoy-Visit": ahoy.getVisitId(),
        "Ahoy-Visitor": ahoy.getVisitorId(),
      },
    });
  }

  private data(): EventProperties {
    const officeObj = getItem<Office>("ridepanda.office", false);
    const vehicleObj = getItem<Vehicle>("ridepanda.lease.vehicle", false);
    const variantObj = getItem<Variant>("ridepanda.lease.variant", false);

    return {
      brand: vehicleObj?.brand,
      color: variantObj?.color,
      email: this.email,
      firebaseId: this.firebaseId,
      hub: officeObj?.serviceArea?.hub.name,
      office: officeObj?.name,
      model: vehicleObj?.model,
      organization: getSlug(),
      size: variantObj?.size,
    };
  }
}

const tracker = Tracker.getInstance();

window.tracker = tracker;

export { tracker };
