import { Box } from "@chakra-ui/react";
import React from "react";
import {
  PaymentIcon,
  type PaymentTypeExtended,
} from "react-svg-credit-card-payment-icons";

type Props = { cardBrand: PaymentTypeExtended };

function CardIconDisplay({ cardBrand }: Props) {
  const paymentIconStyle = { width: 45, borderRadius: 2 };
  const paymentIconContainerStyle = {
    display: "flex",
    position: "absolute",
    top: "10px",
    left: "10px",
    opacity: 0.85,
  };

  return (
    <Box sx={paymentIconContainerStyle} rounded="md">
      <PaymentIcon id={cardBrand} type={cardBrand} style={paymentIconStyle} />
    </Box>
  );
}

const MemoizedCardIcon = React.memo(CardIconDisplay);

export default MemoizedCardIcon;
