import {
  Button,
  Divider,
  Flex,
  Image,
  Link,
  List,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { PopupModal } from "react-calendly";

import Card from "../../../components/Card";
import HubContact from "../../../components/HubContact";

import type { Hub } from "../../../types";

function HubCard({
  hub,
  showServiceButton,
}: {
  hub: Pick<
    Hub,
    "name" | "phone" | "email" | "businessHours" | "directShipEnabled"
  >;
  showServiceButton?: boolean;
}) {
  const { name, phone, email, businessHours, directShipEnabled } = hub;
  const [calendlyVisible, setCalendlyVisible] = useState(false);

  return (
    <>
      <Card
        width={{ base: "100%" }}
        marginTop={{ base: "1em" }}
        innerProps={{ height: "100%", p: 3 }}
      >
        <VStack spacing={3} align="normal">
          <Text>
            <Text as="span" fontWeight="bold" fontSize="md">
              Need help with your subscription or maintenance for your vehicle?
            </Text>
          </Text>
          <Divider />
          {!directShipEnabled && (
            <>
              <Text>Contact your local PandaHub for assistance:</Text>
              <Flex
                alignItems={{ base: "flex-start", md: "center" }}
                justifyContent="space-between"
                pr={8}
                direction={{ base: "column", md: "row" }}
              >
                <Flex mb={{ base: 3, md: 0 }}>
                  <Image
                    src="/assets/repair.png"
                    alt="Ridepanda on Repair"
                    boxSize={{ base: "7em", sm: "7em" }}
                    mr={3}
                    display={{ base: "none", md: "block" }}
                  />
                  <List>
                    <HubContact
                      label={name}
                      phone={phone}
                      email={email}
                      businessHours={businessHours}
                      my={0}
                    />
                  </List>
                </Flex>
                {name === "Seattle" && showServiceButton && (
                  <Button onClick={() => setCalendlyVisible(true)}>
                    Schedule service
                  </Button>
                )}
              </Flex>
            </>
          )}
          {directShipEnabled && (
            <Link href="mailto:support@ridepanda.com?subject=Direct Ship Inquiry">
              <Button>Contact support</Button>
            </Link>
          )}
        </VStack>
      </Card>

      <PopupModal
        url="https://calendly.com/pandahub-seattle/pandahub-maintenance-and-repair"
        onModalClose={() => setCalendlyVisible(false)}
        open={calendlyVisible}
        rootElement={document.body}
      />
    </>
  );
}

export default HubCard;
