import { Button } from "@chakra-ui/react";
import { useState } from "react";
import { PopupModal } from "react-calendly";

import useApp from "../../contexts/AppContext";
import { tracker } from "../../services/tracking";

export default function TestRideButton() {
  const { selectedOffice } = useApp();
  const [visible, setVisible] = useState(false);

  const testRideUrl = selectedOffice?.serviceArea.hub?.testRideUrl;

  const openCalendlyLink = () => {
    if (!testRideUrl) {
      return;
    }

    tracker.trackEvent("Vehicle Test Ride Calendly Popup Clicked");
    setVisible(true);
  };

  return (
    <>
      <Button
        size="lg"
        variant="outline"
        colorScheme="black"
        _hover={{
          bg: "lightGray",
        }}
        _active={{ bg: "gray.300" }}
        onClick={openCalendlyLink}
        w={{ base: "100%", lg: "inherit" }}
      >
        Schedule a test ride
      </Button>

      {testRideUrl && (
        <PopupModal
          url={testRideUrl}
          onModalClose={() => setVisible(false)}
          open={visible}
          rootElement={document.body}
        />
      )}
    </>
  );
}
