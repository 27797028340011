import { gql, useMutation } from "@apollo/client";
import { Maybe } from "graphql/jsutils/Maybe";
import { useCallback } from "react";

import { Err, Ok } from "../types";

export interface SpokeCustomerCreateInput {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  spokeId: string;
  organizationId: string;
  officeId: string;
}

type SpokeCustomer = {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};

export type SpokeCustomerCreatePayload = {
  spokeCustomerCreate: {
    valid: boolean;
    error: Maybe<string>;
    spokeCustomer: Maybe<SpokeCustomer>;
  };
};

export const CREATE_SPOKE_CUSTOMER_MUTATION = gql`
  mutation SpokeCustomerCreate(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $spokeId: ID!
    $organizationId: ID!
    $officeId: ID
  ) {
    spokeCustomerCreate(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      spokeId: $spokeId
      organizationId: $organizationId
      officeId: $officeId
    ) {
      valid
      error
      spokeCustomer {
        id
        firstName
        lastName
        email
        phone
      }
    }
  }
`;

export function useCreateSpokeCustomer() {
  const [createSpokeCustomer] = useMutation<
    SpokeCustomerCreatePayload,
    SpokeCustomerCreateInput
  >(CREATE_SPOKE_CUSTOMER_MUTATION);

  const handleCustomerCreation = useCallback(
    async (variables: SpokeCustomerCreateInput) => {
      try {
        const { errors: apolloError, data } = await createSpokeCustomer({
          variables,
        });

        if (apolloError) return Err(apolloError);
        if (!data) return Err("no data available");
        if (data.spokeCustomerCreate.error)
          return Err(data.spokeCustomerCreate.error);

        return Ok(true);
      } catch (e) {
        return Err(e);
      }
    },
    [createSpokeCustomer],
  );

  return handleCustomerCreation;
}

export default CREATE_SPOKE_CUSTOMER_MUTATION;
