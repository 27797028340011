/* eslint-disable import/no-cycle, no-prototype-builtins */

import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { PopupModal } from "react-calendly";

import useApp from "../../../contexts/AppContext";
import { tracker } from "../../../services/tracking";

import type { Variant, Vehicle } from "../../../types";

type Props = {
  isOpen: boolean;
  onClose(): void;
  onVariantClick?(variant: Variant): void;
  variants: Variant[];
  // biome-ignore lint/suspicious/noExplicitAny: PORTAL LEGACY IS GOING TO DIE SOON
  variantsMap: any;
  vehicle: Vehicle;
  timeToFulfillWeeks: string;
};

export default function AllVariantsModal({
  isOpen,
  onClose,
  onVariantClick = () => {},
  variants,
  variantsMap,
  vehicle,
  timeToFulfillWeeks,
}: Props) {
  const { colorCodes, sizes } = variantsMap;
  const { selectedOffice } = useApp();
  const [calendlyVisible, setCalendlyVisible] = useState(false);

  const colors: { name: string; value: string }[] = useMemo(() => {
    const colorsArray = [];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const property in colorCodes) {
      colorsArray.push({ name: property, value: colorCodes[property] });
    }
    return colorsArray;
  }, [colorCodes]);

  const colorImages = useMemo(() => {
    // biome-ignore lint/suspicious/noExplicitAny: PORTAL LEGACY IS GOING TO DIE SOON
    const images: any = {};

    // biome-ignore lint/complexity/noForEach: PORTAL LEGACY IS GOING TO DIE SOON
    colors.forEach((color) => {
      const variantFound = variants.find(
        (variant) => variant.color === color.name,
      );

      images[color.name] = variantFound?.image.url;
    });

    return images;
  }, [colors, variants]);

  const colorSizes = useMemo(() => {
    const colorsArray = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const size in sizes) {
      // biome-ignore lint/suspicious/noPrototypeBuiltins: PORTAL LEGACY IS GOING TO DIE SOON
      if (sizes.hasOwnProperty(size)) {
        const sizeHasColor = sizes[size];
        const result = colors.map((color) => {
          const availableInSize = !!sizeHasColor[color.name];
          const variantFound = variants.find(
            (variant) => variant.color === color.name && variant.size === size,
          );

          return {
            colorName: color.name,
            availableInSize,
            inStock: variantFound?.inStock,
            variant: variantFound,
          };
        });

        colorsArray.push({ name: size, value: result });
      }
    }

    return colorsArray;
  }, [colors, sizes, variants]);

  const testRideUrl = selectedOffice?.serviceArea.hub?.testRideUrl;
  const openCalendlyLink = () => {
    if (!testRideUrl || !testRideUrl.length) {
      return;
    }

    tracker.trackEvent("Vehicle Test Ride Calendly Popup Clicked");
    setCalendlyVisible(true);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="container.lg">
          <ModalCloseButton
            border="1px solid lightGray"
            borderRadius="full"
            top={8}
            boxSize="44px"
          />
          <ModalBody p={{ base: 0, md: 8 }}>
            <Box p={{ base: 8, md: 0 }} pr={{ base: 14, md: "inherit" }}>
              <Text as="h2" textStyle="h2">
                Pickup availability
              </Text>
              <Text textStyle="sub-heading">{`${vehicle.brand} ${vehicle.model}`}</Text>
            </Box>

            <TableContainer
              border="1px solid #ECECEC"
              borderBottom={{ base: "none", md: "inherit" }}
              borderRadius="xl"
              borderBottomRadius="none"
              mt={8}
            >
              <Table
                sx={{
                  "table, th, td": {
                    border: "1px solid #ECECEC",
                  },
                  "tr:first-of-type, tr:first-of-type th": {
                    borderTop: "none",
                  },
                  "tr:last-child td": {
                    borderBottom: "none",
                  },
                  "td:first-of-type, th:first-of-type": {
                    borderLeft: "none",
                  },
                  "td:last-child, th:last-child": {
                    borderRight: "none",
                  },
                }}
              >
                <Thead>
                  <Tr bg="offWhite">
                    <Th display={{ base: "none", md: "table-cell" }} />
                    {colors.map((color) => (
                      <Th key={color.name}>
                        <Flex align="center" direction="column">
                          <Image
                            src={colorImages[color.name]}
                            height={127}
                            objectFit="contain"
                            display={{ base: "none", md: "inherit" }}
                            mixBlendMode="multiply"
                          />

                          <HStack mt={1}>
                            <Box bg={color.value} boxSize={4} rounded="full" />
                            <Text
                              as="span"
                              fontWeight="semibold"
                              textTransform="capitalize"
                            >
                              {color.name}
                            </Text>
                          </HStack>
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {colorSizes.map((size) => (
                    <Tr key={size.name}>
                      <Td display={{ base: "none", md: "table-cell" }} py={8}>
                        {size.name}
                      </Td>
                      {size.value.map((sizeAvailability) => (
                        <Td textAlign="center" key={sizeAvailability.colorName}>
                          {sizeAvailability.availableInSize && (
                            <Text
                              fontWeight={{ base: "semibold", md: "inherit" }}
                              sx={{
                                _before: {
                                  content: sizeAvailability.inStock
                                    ? `"1-2 days"`
                                    : `"${timeToFulfillWeeks}"`,
                                },
                                _hover: {
                                  textDecor: "underline",
                                  _before: {
                                    content: `"Select variant"`,
                                    cursor: "pointer",
                                  },
                                },
                              }}
                              onClick={() =>
                                onVariantClick(
                                  sizeAvailability.variant as Variant,
                                )
                              }
                            />
                          )}

                          {!sizeAvailability.availableInSize && <Text>-</Text>}

                          <Text
                            display={{ base: "block", md: "none" }}
                            textStyle="small-regular"
                          >
                            {size.name}
                          </Text>
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>

            <Flex
              direction={{ base: "column", md: "row" }}
              p={6}
              border="1px solid #ECECEC"
              borderRadius="xl"
              borderTopRadius="none"
            >
              <Flex align="center">
                <InfoIcon mr={4} alignItems="center" />
                <Flex direction="column">
                  <Text textStyle="semibold">Not sure which one to get?</Text>
                  <Text textStyle="small-regular">
                    Visit our PandaHub for a free consultation and a test ride{" "}
                  </Text>
                </Flex>
              </Flex>
              <Flex
                flex={1}
                align="center"
                justify="end"
                mt={{ base: 4, md: 0 }}
              >
                <Button
                  w={{ base: "100%", md: "inherit" }}
                  onClick={openCalendlyLink}
                >
                  Book a test ride
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {testRideUrl && (
        <PopupModal
          url={testRideUrl}
          onModalClose={() => setCalendlyVisible(false)}
          open={calendlyVisible}
          rootElement={document.body}
        />
      )}
    </>
  );
}
