import {
  Center,
  Icon,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { TbBike, TbCalendarStats, TbClipboardList } from "react-icons/tb";

import ComparePlansTab from "./ComparePlansTab";
import CustomTab from "./components/CustomTab";
import LeaseToOwnTab from "./LeaseToOwnTab";
import SubscriptionTab from "./SubscriptionTab";
import Grid from "../../../components/layout/Grid";
import GridItem from "../../../components/layout/Grid/GridItem";
import Layout from "../../../components/layout/LayoutV2";
import SectionContainer from "../../../components/layout/SectionContainer";
import useApp from "../../../contexts/AppContext";

import type { EnchancedRoute } from "..";

export default function ResultsPage(props: EnchancedRoute) {
  const { hasDirectShipFlow, hasSpokeFlow, selectedOffice } = useApp();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabsChange = async (index: number, sectionToBrowse: string) => {
    setTabIndex(index);
    const element = document.getElementById(sectionToBrowse);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  const leaseToOwnTab = !!selectedOffice?.leaseToOwnTabVisible;
  const subscriptionTab = !!selectedOffice?.subscriptionTabVisible;
  const comparisonTabVisible = !!selectedOffice?.comparisonTabVisible;
  const hasDirectShipAndSpokeFlow = hasDirectShipFlow && hasSpokeFlow;

  return (
    <Layout contentContainerProps={{ maxW: "100%", px: 0, py: 0 }}>
      <Tabs
        index={tabIndex}
        size="lg"
        variant="enclosed"
        p={0}
        onChange={setTabIndex}
      >
        {comparisonTabVisible && (
          <SectionContainer
            innerProps={{}}
            backgroundImage={{
              base: "none",
              md: "url(/assets/vehicles-leftright.png)",
            }}
            backgroundRepeat="no-repeat"
            backgroundPosition="top"
            backgroundSize="1491px 431px"
            minH={431}
          >
            <Grid sx={{ pt: 24, pb: 28 }}>
              <GridItem colSpan={{ base: 2, md: 6 }}>
                <Center>
                  <Text
                    as="h1"
                    textStyle="display"
                    textAlign="center"
                    maxW="500px"
                  >
                    Choose from our two great plans
                  </Text>
                </Center>
              </GridItem>
            </Grid>
          </SectionContainer>
        )}

        {comparisonTabVisible && (
          <Grid pt={3}>
            <GridItem colSpan={{ base: 2, md: 6 }}>
              <TabList
                justifyContent="center"
                borderColor="darkGray"
                px={{ base: "10px", md: "inherit" }}
              >
                <CustomTab
                  icon={<Icon as={TbCalendarStats} />}
                  mobileText="Subscription"
                >
                  {hasDirectShipAndSpokeFlow
                    ? "Month-to-month subscription plan"
                    : "Subscription plan"}
                </CustomTab>
                <CustomTab
                  icon={<Icon as={TbBike} />}
                  mobileText="Lease-to-own"
                >
                  {hasDirectShipAndSpokeFlow
                    ? "Lease-to-own at a partner shop"
                    : "Lease-to-own plan"}
                </CustomTab>
                {!hasDirectShipAndSpokeFlow && (
                  <CustomTab
                    icon={<Icon as={TbClipboardList} />}
                    mobileText="Compare"
                  >
                    Compare the two plans
                  </CustomTab>
                )}
              </TabList>
            </GridItem>
          </Grid>
        )}

        <TabPanels bg="offWhite">
          {subscriptionTab && (
            <TabPanel p={0}>
              <SubscriptionTab wizardProps={props} />
            </TabPanel>
          )}

          {leaseToOwnTab && (
            <TabPanel p={0}>
              <LeaseToOwnTab />
            </TabPanel>
          )}

          {comparisonTabVisible && (
            <TabPanel p={0}>
              <ComparePlansTab handleTabsChange={handleTabsChange} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Layout>
  );
}
