import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Hide,
  Image,
  Input,
  Link,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import { useHistory, useParams } from "react-router-dom";
import { z } from "zod";

import { useCreateSpokeCustomer } from "../../../api/spokeCustomerCreate";
import { useValidateSpokeCustomer } from "../../../api/spokeCustomerValidation";
import { LeaseToOwnAgreementURL } from "../../../constants/spokes";
import useApp from "../../../contexts/AppContext";
import useSpoke from "../../../contexts/SpokeContext";
import { SpokeSignUpCache, SuccessKind } from "../../../types";
import { setSuccessCache } from "../../../utils/successCache";

interface SignUpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SpokeCustomerFormSchema = z.object({
  firstName: z.string().min(1, { message: "Required" }),
  lastName: z.string().min(1, { message: "Required" }),
  phone: z.string().refine(isValidPhoneNumber, {
    message: "Please enter a valid phone number",
  }),
  email: z
    .string()
    .email({ message: "Please enter a valid email address" })
    .min(1),
});

type SpokeCustomerForm = z.infer<typeof SpokeCustomerFormSchema>;

export function SignUpModal({ isOpen, onClose }: SignUpModalProps) {
  const history = useHistory();
  const { slug } = useParams<any>();
  const toast = useToast();

  const { selectedSpoke } = useSpoke();
  const { organization, selectedOffice } = useApp();
  const createCustomer = useCreateSpokeCustomer();
  const validateCustomer = useValidateSpokeCustomer();

  const {
    register,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
  } = useForm<SpokeCustomerForm>({
    resolver: zodResolver(SpokeCustomerFormSchema),
    mode: "onBlur",
  });

  const toastError = useCallback(
    (e) => {
      const error = e || "Something went wrong, try again";
      const title = typeof error === "string" ? error : error.message;

      toast({
        title,
        status: "error",
        duration: 6000,
        isClosable: true,
      });

      // eslint-disable-next-line no-console
      console.error(e);
    },
    [toast],
  );

  const onSubmit: SubmitHandler<SpokeCustomerForm> = useCallback(
    async (formData) => {
      if (!selectedSpoke || !organization || !selectedOffice) {
        toastError(
          "Organization, Spoke and Office must be available in context",
        );
        return;
      }

      const { error } = await validateCustomer({ ...formData });
      if (error) {
        toastError(error);
        return;
      }

      const { ok, error: creationError } = await createCustomer({
        ...formData,
        spokeId: selectedSpoke.id,
        organizationId: organization.id,
        officeId: selectedOffice.id,
      });
      if (!ok) {
        toastError(creationError);
        return;
      }

      setSuccessCache<SpokeSignUpCache>({
        type: SuccessKind.SPOKE_SIGN_UP,
        data: selectedSpoke,
      });

      history.push(`/${slug}/success`);
    },
    [
      createCustomer,
      history,
      organization,
      selectedSpoke,
      selectedOffice,
      slug,
      toastError,
      validateCustomer,
    ],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: "md", lg: "6xl" }}>
      <ModalOverlay />
      <ModalContent borderRadius="2xl" overflow="hidden">
        <Flex p={0}>
          <VStack p={10} maxW="27rem">
            <ModalHeader p={0} mb={8} fontWeight={400}>
              <Heading mb={3}>Sign up to visit a partner bike shop</Heading>
              <Text fontSize="sm">
                We will send you a confirmation code. Bring this to any of our
                partner shops to start your lease.
              </Text>
              <Text fontSize="sm" mt={4}>
                Lease will begin when you check out at our partner shop. Payment
                will be collected on lease start and recur monthly until lease
                term end.{" "}
                <Link
                  textDecoration="underline"
                  fontWeight={400}
                  href={LeaseToOwnAgreementURL}
                >
                  Click here
                </Link>{" "}
                to review Terms and Conditions.
              </Text>
            </ModalHeader>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ width: "100%", height: "100%" }}
            >
              <VStack spacing={4} w="full" minH="31.15rem">
                <FormControl isInvalid={!!errors.firstName} isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input size="lg" {...register("firstName")} />
                  <FormErrorMessage>
                    {errors.firstName?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.lastName} isRequired>
                  <FormLabel>Last Name</FormLabel>
                  <Input size="lg" {...register("lastName")} />
                  <FormErrorMessage>
                    {errors.lastName?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.email} isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input size="lg" {...register("email")} />
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.phone} isRequired>
                  <FormLabel>Phone Number</FormLabel>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        inputComponent={Input}
                        country="US"
                        size="lg"
                        placeholder="(444) 555-6789"
                        {...field}
                      />
                    )}
                  />
                  <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
                </FormControl>
              </VStack>
              <VStack w="full" spacing={4}>
                <Button
                  isLoading={isSubmitting}
                  colorScheme="pandaRed"
                  type="submit"
                  isDisabled={!isValid}
                  w="full"
                >
                  Sign up for a visit
                </Button>
                <Button
                  w="full"
                  colorScheme="gray"
                  variant="outline"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </VStack>
            </form>
          </VStack>
          <Hide below="lg">
            <Box flexGrow={1}>
              <Image
                src="/assets/bike-shop-sign-up.png"
                h="full"
                w="full"
                objectFit="cover"
              />
            </Box>
          </Hide>
        </Flex>
      </ModalContent>
    </Modal>
  );
}

export default SignUpModal;
