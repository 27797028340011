import { initializeApp } from "firebase/app";
import {
  type ActionCodeSettings,
  type ApplicationVerifier,
  GoogleAuthProvider,
  type NextOrObserver,
  RecaptchaVerifier,
  type User,
  isSignInWithEmailLink as firebaseIsSignInWithEmailLink,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  sendSignInLinkToEmail as firebaseSendSignInLinkToEmail,
  signInWithEmailLink as firebaseSignInWithEmailLink,
  signInWithPhoneNumber as firebaseSignInWithPhoneNumber,
  signOut as firebaseSignOut,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import {
  Timestamp,
  addDoc,
  collection,
  getFirestore,
} from "firebase/firestore";

import { getFirebaseConfig } from "./configs";

const firebaseConfig = getFirebaseConfig();

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Auth
export const auth = getAuth(firebaseApp);

export const onAuthStateChanged = (handler: NextOrObserver<User>) =>
  firebaseOnAuthStateChanged(auth, handler);

export const providerGoogle = new GoogleAuthProvider();

export const signInWithPopupGoogle = () =>
  signInWithPopup(auth, providerGoogle);

export const recaptchaVerifier = (recaptchaContainerId: string) =>
  new RecaptchaVerifier(auth, recaptchaContainerId, { size: "invisible" });

export const sendSignInSmsToPhone = (
  phoneNumber: string,
  appVerifier: ApplicationVerifier,
) => firebaseSignInWithPhoneNumber(auth, phoneNumber, appVerifier);

export const sendSignInLinkToEmail = (
  email: string,
  settings: ActionCodeSettings,
) => firebaseSendSignInLinkToEmail(auth, email, settings);

export const signInWithEmailLink = (
  email: string,
  emailLink: string | undefined,
) => firebaseSignInWithEmailLink(auth, email, emailLink);

export const isSignInWithEmailLink = (emailLink: string) =>
  firebaseIsSignInWithEmailLink(auth, emailLink);

export const signOut = async () => firebaseSignOut(auth);

// Database

const db = getFirestore(firebaseApp);

export const addDocToCollection = async (
  collectionName: string,
  documentData: unknown,
) =>
  addDoc(collection(db, collectionName), {
    ...(documentData as object),
    createdAt: Timestamp.fromDate(new Date()),
  });
