import { useQuery } from "@apollo/client";
import {
  Button,
  Container,
  FormControl,
  HStack,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  GET_OFFICES,
  Office,
  OfficesGroupedByServiceArea,
  OfficesQueryResult,
} from "../../../api/offices";
import {
  GET_ORGANIZATIONS_WITH_SLUG_QUERY,
  OrganizationsQueryResult,
} from "../../../api/organizationsWithSlugs";
import GridItem from "../../../components/layout/Grid/GridItem";
import {
  NAVBAR_HEIGHT,
  NAVBAR_MOBILE_HEIGHT,
} from "../../../components/layout/Navbar";
import SectionContainer from "../../../components/layout/SectionContainer";
import PandaLogo from "../../../components/PandaLogo";
import RidepandaTextIcon from "../../../components/RidepandaTextIcon";
import useApp from "../../../contexts/AppContext";
import useAuth from "../../../hooks/useAuth";
import { addSentryBreadcrumb } from "../../../services/sentry";
import { tracker } from "../../../services/tracking";

export default function Form() {
  const { setShowLoginModal } = useAuth();
  const { setSelectedOffice } = useApp();
  const history = useHistory();
  const [organizationSlug, setOrganizationSlug] = useState<string>();
  const { data: orgsData, loading: orgsLoading } =
    useQuery<OrganizationsQueryResult>(GET_ORGANIZATIONS_WITH_SLUG_QUERY);
  const { data: officesData, loading: officesLoading } =
    useQuery<OfficesQueryResult>(GET_OFFICES, {
      variables: { organizationSlug },
      skip: !organizationSlug,
    });
  const [localOffice, setLocalOffice] = useState<Office>();

  const organizations = orgsData?.organizations || [];

  const offices: Array<Office> = useMemo(
    () => officesData?.offices?.nodes || [],
    [officesData?.offices?.nodes],
  );

  const officesByServiceArea = offices.reduce((officesGrouped, office) => {
    const serviceAreaName = office.serviceArea.name;

    if (!officesGrouped[serviceAreaName]) {
      // eslint-disable-next-line no-param-reassign
      officesGrouped[serviceAreaName] = [];
    }

    officesGrouped[serviceAreaName].push(office);

    return officesGrouped;
  }, {} as OfficesGroupedByServiceArea);

  const sortedOfficesByServiceArea = Object.entries(officesByServiceArea)
    .sort(([a], [b]) => a.localeCompare(b))
    .reduce((acc, [serviceArea, groupedOffices]) => {
      acc[serviceArea] = groupedOffices.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      return acc;
    }, {} as OfficesGroupedByServiceArea);

  const onOrganizationChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setOrganizationSlug(e.target.value);
    },
    [],
  );

  const onOfficeChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setLocalOffice(offices.find((office) => office.id === e.target.value));
    },
    [offices, setLocalOffice],
  );

  const onSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();

      if (!localOffice) {
        return;
      }

      tracker.trackEvent("Select Office", {
        selected_office: localOffice.name,
        selected_organization: organizationSlug,
      });

      addSentryBreadcrumb({
        category: "wizard",
        message: `Selected Organization + Office: ${organizationSlug} - ${localOffice.name}`,
        level: "info",
      });

      setSelectedOffice(localOffice);

      history.push(`/${organizationSlug}`);
    },
    [history, organizationSlug, localOffice, setSelectedOffice],
  );

  return (
    <GridItem area="form" colSpan={3} position="relative" zIndex={1} bg="white">
      <Container h="100%" maxW={{ md: "container.md" }}>
        <SectionContainer
          h={{
            base: `${NAVBAR_MOBILE_HEIGHT}px`,
            md: `${NAVBAR_HEIGHT}px`,
          }}
          innerProps={{ px: 0 }}
          py={3}
          position="absolute"
        >
          <HStack>
            <PandaLogo boxSize={10} />
            <RidepandaTextIcon width="auto" height="24px" />
          </HStack>
        </SectionContainer>

        <Stack
          spacing={6}
          direction="column"
          justify="center"
          h="100%"
          py={{
            base: `${NAVBAR_MOBILE_HEIGHT}px`,
            md: `${NAVBAR_HEIGHT}px`,
          }}
        >
          <Text as="h1" textStyle={{ base: "h1", md: "display" }}>
            Welcome to Ridepanda!
          </Text>
          <Text textStyle="sub-heading" fontSize="md" color="black">
            Get the perfect light electric vehicle for your daily commute!
            Select your organization and office to start browsing.
          </Text>

          <form onSubmit={onSubmit}>
            <Stack spacing={3} direction={{ base: "column", md: "row" }} mt={6}>
              <FormControl id="organizationSlug" maxWidth={{ xl: "200px" }}>
                <Select
                  placeholder="Organization"
                  size="lg"
                  onChange={onOrganizationChange}
                  isDisabled={orgsLoading}
                >
                  {organizations.map((organization) => {
                    return (
                      <option value={organization.slug} key={organization.slug}>
                        {organization.name}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl id="office">
                <Select
                  placeholder="Office"
                  size="lg"
                  disabled={!organizationSlug || offices.length === 0}
                  onChange={onOfficeChange}
                >
                  {Object.entries(sortedOfficesByServiceArea).map(
                    ([serviceArea, saOffices]) => {
                      return (
                        <optgroup label={serviceArea} key={serviceArea}>
                          {saOffices.map((office) => (
                            <option value={office.id} key={office.id}>
                              {office.name}
                            </option>
                          ))}
                        </optgroup>
                      );
                    },
                  )}
                </Select>
              </FormControl>
              <Button
                type="submit"
                isLoading={orgsLoading || officesLoading}
                isDisabled={!localOffice || !organizationSlug}
                size="lg"
                w={{ base: "100%", md: 250 }}
              >
                Get Started
              </Button>
            </Stack>
          </form>

          <Text fontSize="md" color="darkGray" textAlign="left">
            Already have a vehicle from us?
            <Button
              onClick={() => setShowLoginModal(true)}
              variant="link"
              ml={1}
              textDecor="underline"
            >
              Log in to your account
            </Button>
          </Text>
        </Stack>
      </Container>
    </GridItem>
  );
}
