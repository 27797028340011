import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Redirect, useParams } from "react-router-dom";

import { InvoiceLineItems } from "./InvoiceLineItems";
import {
  type CheckoutSessionData,
  type InvoiceLines,
  createCheckoutSession,
} from "../../../api/leases";
import Card from "../../../components/Card";
import Faqs from "../../../components/Faqs";
import Grid from "../../../components/layout/Grid";
import GridItem from "../../../components/layout/Grid/GridItem";
import Layout from "../../../components/layout/LayoutV2";
import { CHECKOUT_SESSION_ERROR } from "../../../constants/errors";
import useApp from "../../../contexts/AppContext";
import CheckoutForm from "../CheckoutForm";
import Breadcrumbs from "../components/Breadcrumbs";
import CheckoutFormSkeleton from "../components/CheckoutFormSkeleton";
import { ContactSupportCard } from "../ResultsPage/SubscriptionTab/DirectShipContent";

interface ErrorToastDescriptionProps {
  errorMessage: string;
  onClose: () => void;
}

function ErrorToastDescription({
  errorMessage,
  onClose,
}: ErrorToastDescriptionProps) {
  return (
    <VStack spacing={4}>
      <Text>{CHECKOUT_SESSION_ERROR}</Text>
      <ButtonGroup variant="outline" spacing="6" alignItems="space-between">
        <Button variant="outline" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="outline"
          onClick={() => navigator.clipboard.writeText(errorMessage)}
        >
          Copy support data
        </Button>
      </ButtonGroup>
    </VStack>
  );
}

export function Review() {
  const toast = useToast();
  const toastIdRef = useRef<string | number>();
  const [stripeClientSecret, setStripeClientSecret] = useState<
    string | undefined
  >(undefined);
  const [stripeClientSecretType, setStripeClientSecretType] = useState<
    string | undefined
  >(undefined);
  const [invoiceLines, setInvoiceLines] = useState<InvoiceLines | undefined>(
    undefined,
  );
  const { slug } = useParams() as { slug: string };
  const {
    selectedOffice,
    selectedVehicle,
    selectedVariant,
    customerEmail,
    hasDirectShipFlow,
  } = useApp();

  const hasFetchedSession = useRef(false); // New ref to track session fetch

  const isInfoComplete =
    !!selectedOffice &&
    !!selectedVehicle &&
    !!selectedVariant &&
    !!customerEmail;

  const closeToastHandler = useCallback(() => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }, [toast]);

  useEffect(() => {
    async function getSession() {
      if (!isInfoComplete || hasFetchedSession.current) return; // Exit if already fetched

      hasFetchedSession.current = true; // Mark as fetched

      try {
        const {
          checkoutSessionCreate: {
            clientSecret,
            clientSecretType,
            discount,
            taxRate,
            total,
          },
        } = (await createCheckoutSession(
          {
            variantId: selectedVariant.id,
            officeId: selectedOffice.id,
            email: customerEmail,
            organizationSlug: slug,
          },
          selectedOffice.serviceArea?.hub.id,
        )) as CheckoutSessionData;

        setStripeClientSecret(clientSecret);
        setStripeClientSecretType(clientSecretType);
        setInvoiceLines({
          taxRate,
          discount,
          total,
        });
      } catch (ex) {
        const errorMessage = ex instanceof Error ? ex.message : (ex as string);

        toastIdRef.current = toast({
          variant: "subtle",
          title: "Problem with payment processing",
          description: (
            <ErrorToastDescription
              errorMessage={errorMessage}
              onClose={closeToastHandler}
            />
          ),
          status: "error",
          isClosable: true,
          duration: null,
        });

        // eslint-disable-next-line no-console
        console.error(ex);
      }
    }

    getSession();
  }, [
    isInfoComplete,
    selectedVariant?.id,
    selectedOffice?.id,
    selectedOffice?.serviceArea?.hub.id,
    customerEmail,
    slug,
    toast,
    closeToastHandler,
  ]);

  if (!isInfoComplete) {
    return <Redirect to={`/${slug}/wizard`} />;
  }

  return (
    <Layout>
      <Grid
        mt={8}
        templateAreas={{
          base: `"header"
                 "info"
                 "form"`,
          md: `"header info"
               "form info"`,
        }}
        gridTemplateRows="min-content 1fr"
        gap={0}
        columnGap={{ base: 6, md: 8 }}
        rowGap={0}
      >
        <GridItem colSpan={3} pr={{ base: 0, md: "3rem" }} area="header">
          <Breadcrumbs justifyContent="start" />
          <VStack spacing={6} my={6} alignItems="start">
            <Box>
              <Text as="h1" textStyle="h1">
                Review Subscription
              </Text>
              {hasDirectShipFlow && (
                <Text fontSize="md" fontWeight={500} mb={2} mt={3}>
                  We will charge you for the first month of your subscription
                  today. Your subscription will begin once your vehicle is
                  delivered.
                </Text>
              )}
              {!hasDirectShipFlow && (
                <Text fontSize="md" fontWeight={500} mb={2} mt={3}>
                  We will charge you for the first month of your subscription
                  today. Your subscription will begin once you pick up your
                  vehicle.
                </Text>
              )}
            </Box>
          </VStack>
        </GridItem>
        <GridItem colSpan={3} pr={{ base: 0, md: "3rem" }} area="form">
          <VStack w="full" spacing={6} pt={{ base: "3rem", md: 0 }}>
            <VStack w="full" spacing={3} pb={2}>
              <Divider />
              <Box textAlign="center" w="full">
                <Text fontSize="xs">{customerEmail}</Text>
              </Box>
              <Divider />
            </VStack>
            {!(stripeClientSecret && stripeClientSecretType) ? (
              <CheckoutFormSkeleton />
            ) : (
              <CheckoutForm
                clientSecret={stripeClientSecret}
                clientSecretType={stripeClientSecretType}
                invoiceLines={invoiceLines}
              />
            )}
          </VStack>
        </GridItem>
        <GridItem colSpan={3} area="info">
          <Card borderWidth={{ base: 0, md: "1px" }}>
            <VStack align="start" spacing={8}>
              <Image src={selectedVariant?.image.url} px={66} pt={6} />
              <Box px={{ base: 0, md: 8 }}>
                <Text as="h3" textStyle="h3" mb={1}>
                  {selectedVehicle.brand} {selectedVehicle.model}
                </Text>
                <Text>
                  {selectedVariant.size} • {selectedVariant.color}
                </Text>
              </Box>
              <Divider />
              <InvoiceLineItems
                lines={invoiceLines}
                planPrice={selectedVehicle.plan.price}
              />
              <Divider />
              {!hasDirectShipFlow && (
                <>
                  <VStack
                    px={{ base: 0, md: 8 }}
                    pb={{ base: 0, md: 8 }}
                    alignItems="start"
                    spacing={2}
                  >
                    <Text fontWeight="semibold" textAlign="left">
                      Your subscription also includes
                    </Text>
                    <UnorderedList spacing={1} px={4}>
                      <ListItem>Accessories — Helmet & Lock</ListItem>
                      <ListItem>
                        Expert assembly including tuning & quality testing
                      </ListItem>
                      <ListItem>Vehicle onboarding & Panda Swag</ListItem>
                      <ListItem>Unlimited virtual video support</ListItem>
                      <ListItem>In-person repair as needed</ListItem>
                      <ListItem>4x annual tune-ups</ListItem>
                    </UnorderedList>
                  </VStack>
                  <Divider
                    display={{ base: "initial", md: "none" }}
                    boxShadow="xs"
                  />
                </>
              )}
            </VStack>
          </Card>
        </GridItem>
      </Grid>
      {!hasDirectShipFlow && (
        <Flex direction="column" mt={24}>
          <Faqs />
        </Flex>
      )}
      {hasDirectShipFlow && (
        <ContactSupportCard backgroundColor="transparent" />
      )}
    </Layout>
  );
}

export default Review;
