import { Icon, type IconProps } from "@chakra-ui/react";

export default function Range(props: IconProps) {
  return (
    <Icon viewBox="0 0 48 48" {...props}>
      <path
        d="M21 4C20.0435 4 19.1915 4.44766 18.6422 5.14489C17.9586 6.01253 17.1046 7 16 7H14C12.8954 7 12 7.89543 12 9V41C12 42.1046 12.8954 43 14 43H34C35.1046 43 36 42.1046 36 41V9C36 7.89543 35.1046 7 34 7H32C30.8954 7 30.0414 6.01253 29.3578 5.14489C28.8085 4.44766 27.9565 4 27 4H21Z"
        fill="black"
      />
      <rect x="16" y="13" width="16" height="5" rx="1" fill="#F5F5F5" />
      <rect x="16" y="20" width="16" height="5" rx="1" fill="#F5F5F5" />
      <rect x="16" y="27" width="16" height="5" rx="1" fill="#F5F5F5" />
      <rect x="16" y="34" width="16" height="5" rx="1" fill="#F5F5F5" />
    </Icon>
  );
}
