import { gql } from "@apollo/client";

export const GET_OFFICES = gql`
  query Offices($organizationSlug: String) {
    offices(organizationSlug: $organizationSlug) {
      nodes {
        id
        name
        slug
        comparisonTabVisible
        leaseToOwnTabVisible
        subscriptionTabVisible
        programsAvailable
        organization {
          name
          slug
        }
        serviceArea {
          name
          hub {
            id
            name
            testRideUrl
            pandacareUrl
            locationsPageCopy
            googleMapsUrl
            fullAddress
            address {
              city
              countryCode
              line1
              line2
              postalCode
              stateCode
            }
            directShipEnabled
          }
        }
      }
    }
  }
`;

export enum RidepandaProgramKind {
  /** Direct ship program kind */
  DirectShip = "DIRECT_SHIP",
  /** Hub program kind */
  Hub = "HUB",
  /** Spoke program kind */
  Spoke = "SPOKE",
}

type Organization = {
  name: string;
  slug: string;
};

type Address = {
  city: string;
  countryCode: string;
  line1: string;
  line2?: string | null;
  postalCode: string;
  stateCode: string;
};

type Hub = {
  id: string;
  name: string;
  testRideUrl: string;
  pandacareUrl: string;
  locationsPageCopy: string;
  googleMapsUrl: string;
  fullAddress: string;
  directShipEnabled: boolean;
  address: Address;
};

type ServiceArea = {
  name: string;
  hub: Hub;
};

export type Office = {
  id: string;
  name: string;
  slug: string;
  comparisonTabVisible: boolean;
  leaseToOwnTabVisible: boolean;
  subscriptionTabVisible: boolean;
  programsAvailable: Array<RidepandaProgramKind>;
  organization: Organization;
  serviceArea: ServiceArea;
};

export type OfficesQueryResult = {
  offices?: {
    nodes: [Office];
  };
};

export type OfficesGroupedByServiceArea = {
  [key: string]: Array<Office>;
};
