import { Icon, type IconProps } from "@chakra-ui/react";

type DollarIconProps = {
  svgProps?: {
    d?: string;
    pathLength?: number;
    fill?: string;
  };
} & IconProps;

export default function DollarIcon({ svgProps, ...props }: DollarIconProps) {
  return (
    <Icon viewBox="0 0 20 30" {...props}>
      <path
        d="M10.2679 26.5757C8.13614 26.5757 6.1702 26.2204 4.37006 25.5098C2.59362 24.7755 1.13693 23.7689 0 22.4898L2.91338 18.7238C3.43447 19.4107 4.08583 20.0383 4.86747 20.6068C5.67279 21.1516 6.56102 21.5898 7.53214 21.9214C8.52696 22.2293 9.54545 22.3833 10.5876 22.3833C11.4877 22.3833 12.222 22.2885 12.7904 22.099C13.3826 21.8858 13.8208 21.6016 14.105 21.2463C14.3892 20.891 14.5314 20.4647 14.5314 19.9673C14.5314 19.3988 14.259 18.9251 13.7142 18.5461C13.1931 18.1671 12.4115 17.8711 11.3693 17.6579L7.56767 16.8763C6.05177 16.5683 4.80825 16.1183 3.83713 15.5262C2.88969 14.9103 2.17911 14.1642 1.70539 13.2878C1.25536 12.3878 1.03034 11.393 1.03034 10.3034C1.03034 8.92962 1.38563 7.70979 2.09621 6.64392C2.83048 5.55436 3.8845 4.70167 5.25829 4.08583C6.65576 3.44631 8.32562 3.12655 10.2679 3.12655C12.3286 3.12655 14.1168 3.45815 15.6328 4.12136C17.1487 4.76088 18.4869 5.79122 19.6475 7.21238L16.6631 10.5166C15.763 9.45071 14.7919 8.64538 13.7497 8.10061C12.7075 7.55583 11.4877 7.28344 10.0902 7.28344C9.14279 7.28344 8.373 7.40187 7.78085 7.63873C7.1887 7.8519 6.73866 8.14798 6.43074 8.52695C6.14651 8.90593 6.0044 9.34412 6.0044 9.84153C6.0044 10.2442 6.12283 10.6113 6.35969 10.9429C6.59655 11.2745 6.95184 11.5588 7.42556 11.7956C7.92296 12.0088 8.5388 12.1983 9.27306 12.3641L13.6076 13.2878C14.7919 13.5484 15.8222 13.9274 16.6986 14.4248C17.5987 14.9222 18.2856 15.5617 18.7593 16.3433C19.2567 17.1013 19.5054 18.0132 19.5054 19.0791C19.5054 20.7134 19.1027 22.0872 18.2974 23.2004C17.4921 24.3137 16.3907 25.1545 14.9932 25.723C13.6194 26.2914 12.0443 26.5757 10.2679 26.5757ZM8.34931 29.7022V0H12.1154V29.7022H8.34931Z"
        fill="#E05F36"
        {...svgProps}
      />
    </Icon>
  );
}
