import { Icon, type IconProps } from "@chakra-ui/react";

export default function SquareRoundCheckIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 19" {...props}>
      <path
        d="M6.75 9.5L8.25 11L11.25 8M9 2.75C14.4 2.75 15.75 4.1 15.75 9.5C15.75 14.9 14.4 16.25 9 16.25C3.6 16.25 2.25 14.9 2.25 9.5C2.25 4.1 3.6 2.75 9 2.75Z"
        stroke="#332B04"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="white"
      />
    </Icon>
  );
}
