import { Icon, type IconProps } from "@chakra-ui/react";

export default function PancaCareIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8V0H8L8 8H0V16H8L8 24H16V16H24V8H16Z"
        fill="white"
      />
    </Icon>
  );
}
