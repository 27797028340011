import { Box, Flex, Image, Link, Select, Text } from "@chakra-ui/react";

import Card from "../Card";
import GridItem from "../layout/Grid/GridItem";

type Props = {
  value: string;
  onChange?: (e: any) => void;
};

export default function HelmetSizeForm(props: Props) {
  const { onChange, value } = props;
  return (
    <>
      <Card
        borderWidth={{ base: 0, md: "1px" }}
        borderRadius={16}
        p={6}
        pr={62}
      >
        <Box w="full" mb={2}>
          <GridItem colSpan={3}>
            <Flex alignItems="center" h="100%" gap="4px">
              <Image src="/assets/perks/helmet.svg" mr={4} />
              <Flex alignItems="flex-start" direction="column">
                <Text textStyle="large-regular">
                  <Text fontSize="md">
                    As part of your subscription, we provide you with a brand
                    new helmet.
                  </Text>
                  <Link
                    href="https://ridepanda.notion.site/Ridepanda-Helmet-Sizing-Guide-3c8244b38c2c475e93a60c6e1ae983fb"
                    isExternal
                    fontSize="md"
                    textDecor="underline"
                    color="pandaRed.500"
                  >
                    See helmet sizing guide
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </GridItem>
        </Box>
      </Card>
      <Box w="full">
        <Text fontWeight="semibold" mb={2}>
          Your helmet size
        </Text>
        <Select
          id="size-select"
          placeholder="Select"
          size="lg"
          name="size"
          onChange={(e) => onChange && onChange(e.target.value)}
          value={value}
        >
          <option value="small_medium">Small/Medium</option>
          <option value="large">Large</option>
          <option value="extra_large">Extra Large</option>
        </Select>
      </Box>
    </>
  );
}
