import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Button, Flex, Link, Td, Text, Tr } from "@chakra-ui/react";

import CurrencyText from "../../../../components/CurrencyText";
import { Invoice } from "../../../../types";

type Props = {
  invoice: Invoice;
};

export function formatDate(
  date: Date,
  options: Intl.DateTimeFormatOptions | undefined = undefined,
) {
  return date.toLocaleDateString("en-US", options);
}

export default function InvoiceRow({ invoice }: Props) {
  const createdAt = formatDate(new Date(invoice.createdAt), {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return (
    <Tr>
      <Td>
        <Text fontWeight="semibold">{createdAt}</Text>
      </Td>
      <Td>
        <CurrencyText
          value={Number(invoice.amountPaidCents) / 100}
          valueFontWeight="semibold"
          currency="USD"
          decimals={2}
        />
      </Td>
      <Td>
        <Flex flex={1} justify="end">
          <Button
            as={Link}
            rightIcon={<ExternalLinkIcon />}
            size="xs"
            variant="outline"
            colorScheme="gray"
            href={invoice.stripePaymentUrl}
            isExternal
          >
            View
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
}
