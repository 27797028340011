import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";

import ForceLightMode from "./components/ForceLightMode";
import Fonts from "./components/layout/Fonts";
import ScrollToTopOnNavigation from "./components/ScrollToTopOnNavigation";
import { AppProvider } from "./contexts/AppContext";
import { AuthProvider } from "./hooks/useAuth";
import { apolloClient } from "./services/apolloClient";
import Routes from "./services/Routes";
import defaultTheme from "./theme/defaultTheme";

function App() {
  return (
    <ChakraProvider theme={defaultTheme}>
      <Fonts />
      <ForceLightMode />
      <Router>
        <ScrollToTopOnNavigation />
        <AuthProvider>
          <ApolloProvider client={apolloClient}>
            <AppProvider>
              <Routes />
            </AppProvider>
          </ApolloProvider>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  );
}

export default App;
