import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { OnChangeValue } from "react-select";

import { GET_OFFICES, Office, OfficesQueryResult } from "../../api/offices";
import useApp from "../../contexts/AppContext";
import { tracker } from "../../services/tracking";
import PandaSelect from "../PandaSelect";

type Props = { isOpen?: boolean; onClose?: () => void };

export default function OfficeSelectorModal({
  isOpen = false,
  onClose = () => {},
}: Props) {
  const { setSelectedOffice, organization } = useApp();
  const [office, setOffice] = useState<Office>();

  const { data, loading } = useQuery<OfficesQueryResult>(GET_OFFICES, {
    variables: { organizationSlug: organization?.slug },
    skip: !organization,
  });

  const offices = useMemo(() => data?.offices?.nodes || [], [data]);

  const officeOptions = useMemo(
    () =>
      offices.map((o) => ({
        value: o.slug,
        label: o.name,
      })),
    [offices],
  );

  const onOfficeChange = useCallback(
    (newValue: OnChangeValue<{ value: string }, false>) => {
      if (!newValue) {
        return;
      }

      const officeSlug = newValue.value;
      const foundOffice = offices.find((o) => o.slug === officeSlug);
      setOffice(foundOffice);

      if (foundOffice) {
        tracker.trackEvent("Select Office", {
          selected_office: foundOffice.name,
          selected_organization: organization?.name,
        });
      }
    },
    [offices, setOffice, organization?.name],
  );

  const handleConfirm = useCallback(() => {
    if (!office) {
      return;
    }

    setSelectedOffice(office);
  }, [office, setSelectedOffice]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Your Office</ModalHeader>
        <ModalBody>
          <Box w="100%">
            <PandaSelect
              id="officeSelector"
              options={officeOptions}
              onChange={onOfficeChange as any}
              isDisabled={loading}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="outline"
            colorScheme="black"
            _hover={{
              bg: "lightGray",
            }}
            _active={{ bg: "gray.300" }}
            isDisabled={!office}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
