/* eslint-disable import/no-cycle */
import { Button, HStack, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { PaymentIcon } from "react-svg-credit-card-payment-icons";

import UpdatePaymentMethodModal from "../../../components/UpdatePaymentMethodModal";

function getMonthName(monthIndex: number) {
  return new Date(2023, monthIndex - 1).toLocaleString("en-US", {
    month: "short",
  });
}

// biome-ignore lint/suspicious/noExplicitAny: PORTAL LEGACY IS GOING TO DIE SOON
function PaymentMethod({ brand, last4, expirationMonth, expirationYear }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack align="left">
      <HStack>
        <PaymentIcon
          id={brand.toLocaleLowerCase()}
          type={brand.toLocaleLowerCase()}
          width={45}
          style={{ borderRadius: 2 }}
        />
        <VStack alignItems="flex-start" spacing={0}>
          <Text fontSize="md" textTransform="capitalize">
            {brand} •••• {last4}
          </Text>
          <Text fontSize="md" color="gray.500" mt={0}>
            Expires {getMonthName(expirationMonth)} {expirationYear}
          </Text>
        </VStack>
      </HStack>
      <Button size="xs" variant="outline" onClick={onOpen}>
        Update your card
      </Button>
      <UpdatePaymentMethodModal isOpen={isOpen} onClose={onClose} />
    </VStack>
  );
}

export default PaymentMethod;
